.messagerie .chat {
  margin-top: auto;
  margin-bottom: auto;
}
#messagerie-desktop .messagerie .card {
  /* height: auto !important; */
}
#messagerie-desktop .messagerie .contacts li {
  width: 100% !important;
  padding: 15px 10px;
  margin-bottom: 1px !important;
  background: #f9f9f9;
}
#messagerie-desktop .messagerie .contacts li.active {
  background: #003da5 !important;
  /* color: #fff !important; */
}

#messagerie-desktop .messagerie .contacts li.active span,
#messagerie-desktop .messagerie .contacts li.active p {
  /* background: #003DA5; */
  color: #fff !important;
}
#messagerie-desktop .card-header {
  background: #fff;
}
.messagerie .card {
  height: 500px;
  border-radius: 15px !important;
  background-color: #fff;
}
.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
  height: 700px;
}
.contacts_body::-webkit-scrollbar {
  width: 0px;
  height: 100%;
}
.contacts_body::-webkit-scrollbar-track {
  background: transparent;
}
.contacts_body::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.msg_card_body {
  overflow-y: auto;
  height: 500px;
}
.msg_card_body::-webkit-scrollbar {
  width: 0px;
  height: 100%;
}
.msg_card_body::-webkit-scrollbar-track {
  background: transparent;
}
.msg_card_body::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}
.messagerie .card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.messagerie .card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
  background-color: transparent !important;
}
.messagerie .container {
  align-content: center;
}
.messagerie .search {
  border-radius: 15px 0 0 15px !important;
  background-color: #fff !important;
  border: 0 !important;
  color: white !important;
}
.messagerie .search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: #003da5 !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: #003da5 !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.messagerie .send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: #003da5 !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.messagerie .search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: #003da5 !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.messagerie .contacts {
  list-style: none;
  padding: 0;
}
.messagerie .contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
  background: #f9f9f9;
}

.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.messagerie .online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #42922e;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.messagerie .offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user_info span {
  color: #000;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.user_info p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #000;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 40px 40px 40px 0;
  background-color: #eaeaea;
  padding: 15px 40px 10px 15px;
  position: relative;
  color: #000;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 40px 40px 0 40px;
  background-color: #42922e;
  padding: 20px 20px 10px 40px;
  position: relative;
}
.message-item-send {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0235294em;
  color: #ffffff;
  padding-bottom: 15px;
}
.msg_time {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.0235294em;
  color: #817b7b;
}
.content-time-msg-send {
  display: flex;
  justify-content: flex-end;
}
.msg_time_send {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.0235294em;
  color: #ffffff;
}
.name-chat-send-admin {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 14px;
  letter-spacing: 0.0235294em;
  color: #ffffff;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.messagerie .action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.messagerie .action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.messagerie .action_menu ul li i {
  padding-right: 10px;
}
.messagerie .action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}

.user_item {
  cursor: pointer;
  list-style: none;
}

#contacts_body .user_item.active .user_info span {
  color: #fff !important;
}
#contacts_body .user_item.active .user_info p {
  color: #fff !important;
}
.name-admin-chat-send {
  font-family: "Mulish";
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #817b7b;
}
.content-msg-time {
  display: flex;
  justify-content: flex-end;
}
.text-message-item {
  padding-bottom: 15px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0235294em;
  color: #000;
}
