.titre-nous-contacter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #003DA5;

}
.text-nous-contacter-incident {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
}
.input-form-incident {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #444150;

}
.label-form {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
}