
.component-front-register{
   padding: 150px;
  background: rgba(245,245,245,255);
  border-radius: 10px;  
}

.color-part1{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.6);
}
#register i.fa {
    margin-left: 0 !important;
}
#register span {
    opacity: inherit !important;
}
.color-part2{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #BB4411;
}

.color-part2:hover{
    color: #BB4411;
}
.btn-inscription {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.025em;
    color: #FFFFFF;
    background: #BB4411;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 12px;
}
.btn-inscription:hover {
    text-decoration: none;
    color: #BB4411;
    border: 1px solid #BB4411;
    background: #FFFFFF;
}

.form-radio-input {
    display: flex;
    flex-wrap: wrap;
}
label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
   /* Accessible outline */
   /* Remove comment to use */
   /* &:focus-within {
        outline: .125em solid $primary-color;
   }
    */
}
.radio-input label {
    margin-left: 3px;
    margin-right: 3px;
}
.radio-input label input {
    position: absolute;
    left: -9999px;
}
.radio-input label input:checked + span {
    background-color: #fff;
    border: 1px solid #003DA5;
    box-sizing: border-box;
    border-radius: 10px;
}
.radio-input label input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em #003DA5;
}
.radio-input label span {
    /* display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 10px;
    transition: 0.25s ease;
    border: 1px solid #E1E1E1;
    height: 59px; */
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: 0.25s ease;
    border: 1px solid #E1E1E1;
    padding: 7px;
}
.radio-input label span:hover {
    background-color: #fff;
    border: 2px solid #003DA5;
}
.radio-input label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #666666;
}
.container-radio-input {
    /* position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0; */
    width: 100%;
    display: flex;
    align-items: center;
    /* padding: 10px; */
}
.row-statut {
    display: flex;
    justify-content: start;
}
.required-label {
    color: #003DA5;
    padding-left: 2px;
    font-weight: 900;
    display: inline-block;
}
.statut-label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 24px;
    color: #444150 !important;
}

.register-pays {
    border: 1px solid #D9D9D9;
    padding: 10px 10px;
    color: #444150;
    border-radius: 6px;
}
.tag {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    background: #ECEEF2;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.7);
    padding: 8px;
    margin: 16px;
}
