.newmessage-admin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  border-radius: 100px;
  color: #ffffff;
  padding: 1rem;
}
.new-status {
  background: #1a5c8c;
}

.warning-status {
  background: #ca8d19;
}

.success-status {
  background: #42922e;
}

.name-auteur-message-admin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #42922e;
}
.message-recu-admin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #42922e;
}
