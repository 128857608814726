.main {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  position: fixed;
  right: 0;
  bottom: 2%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: #003da5;
  box-shadow: 3px 2px 3px rgba(128, 128, 128, 0.5);
}
.main:hover {
  background: transparent;
  box-shadow: inherit;
}

.top {
  display: flex;
}

.bottom {
  display: flex;
}

.square {
  min-height: 50px;
  min-width: 50px;
  transition-duration: 0.5s;
  z-index: -5;
  position: absolute;
}

.sign {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.5s;
}

.line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  transition-duration: 0.5s;
}

.main:hover .line {
  transition-duration: 0.5s;
  background-color: rgb(0, 0, 0);
}

.main:hover .sign {
  transform: rotate(135deg) scale(0.8);
  transition-duration: 0.25s;
  transition-timing-function: linear;
  opacity: 0.7;
}

.main:hover .square {
  transition-duration: 0.5s;
  border-radius: 5px;
  cursor: pointer;
  min-height: 0px;
  min-width: 0px;
  background-color: white;
}

.main:hover .square::before {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 2px auto;
  transform: translate(-50%, -50%);
}

.main .one {
  top: 0%;
  left: 0%;
}

.main:hover .one::before {
  content: url(../../images/icons/signaler.png);
}

.main:hover .one {
  background-color: transparent;
  box-shadow: inherit;
}

.main .two {
  top: 0%;
  right: 0%;
}

.main:hover .two::before {
  content: url(../../images/icons/contact.png);
}

.main:hover .two {
  background-color: transparent;
  box-shadow: inherit;
}

.main .three {
  bottom: 0%;
  left: 0%;
}

.main:hover .three::before {
  content: url(../../images/icons/Frame_1692.png);
}

.main:hover .three {
  /*     background-image: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);  */
  background-color: transparent;
  box-shadow: inherit;
}

.main .four {
  right: 0%;
  bottom: 0%;
}

.main:hover .four::before {
  content: url(https://image.flaticon.com/icons/png/32/25/25231.png);
}

.main:hover .four {
  background-color: transparent;
  box-shadow: inherit;
}

.ver {
  height: 30px;
  width: 5px;
}

.hor {
  height: 5px;
  width: 30px;
}

.btn-close-popup {
  cursor: pointer;
  position: relative;
  right: -5px;
  top: 5px;
  margin: 3px;
  width: 20px;
  height: 20px;
  float: right;
}
.clear {
  clear: both;
}
.btn-close-popup::before,
.btn-close-popup::after {
  content: "";
  height: 2px;
  display: block;
  background: #d6d6d6;
}
.btn-close-popup::before {
  margin-top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn-close-popup::after {
  margin-top: -2px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.btn-close-popup:hover:after,
.btn-close-popup:hover:before {
  background: #ff8cb4;
}
.modal-wrapper {
  display: flex;
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  background: rgba(25, 18, 12, 0.3);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.modal-wrapper.open {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=1);
}
.modal-popup-chat {
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  height: 305px;
  width: 470px;
  position: relative;
  margin: auto;
  background: #e8e8e8;
  background: -webkit-linear-gradient(bottom, #e8e8e8, #fff);
  background: -o-linear-gradient(bottom, #e8e8e8, #fff);
  background: linear-gradient(to top, #e8e8e8, #fff);
  box-shadow: 0px 16px 16px -6px rgba(47, 46, 38, 0.5);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-transform: scale(0.7);
  /* IE 9 */
  -webkit-transform: scale(0.7);
  /* Safari */
  transform: scale(0.7);
}
.modal-wrapper.open .modal-popup-chat {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-transform: scale(1);
  /* IE 9 */
  -webkit-transform: scale(1);
  /* Safari */
  transform: scale(1);
}
.content {
  text-align: center;
}
@media screen and (max-width: 425px) {
  .modal-popup-chat {
    width: 100%;
    height: 70%;
    padding: 0;
  }
  .btn-close-popup {
    right: 5px;
  }
}
.container-popup-chat-box {
  background: #fff;
  padding-bottom: 20px;
}
.content-header-popup-title {
  background: #003da5;
  padding: 10% 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-popup-chat-box {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.291667px;
  color: #fff;
}
.content-popup-chat-bloc {
  margin: -1rem 1.5rem 2rem;
  padding: 20px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 5%);
  background-color: #fff;
  position: relative;
}
[role="tooltip"].popup-content {
  width: 500px !important;
  right: 2%;
  left: inherit !important;
  padding: 0 !important;
  top: 10% !important;
  position: fixed !important;
}
.btn-submit-chat {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.291667px;
  color: #fff;
  background: #003da5;
  padding: 10px;
  width: 100%;
}
.btn-submit-chat:hover {
  color: #003da5;
  border: 1px solid #003da5;
  background: #fff;
}
.popup-content > div {
  left: inherit !important;
  right: 5%;
}
.container-discussion-bloc {
  overflow-y: scroll;
  padding: 5%;
  height: 300px;
}
.container-discussion-bloc::-webkit-scrollbar {
  width: 6px;
}
.container-discussion-bloc::-webkit-scrollbar-track {
  background: #fff;
}
.container-discussion-bloc::-webkit-scrollbar-thumb {
  background-color: #003da5;
  border-radius: 20px;
  border: 3px solid transparent;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: inherit !important;
}
