.agenda-tabs-nav {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    color: #011F3B;
}
.agenda-tabs-nav.active {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #011F3B;

}
.agenda-tabs-nav:hover {
    border: none;
    color: #011F3B;
}
.navtabs-agenda-coordonnateur {
    border-bottom: none;
}
.position-btn-add-event-coordonnateur{
    position: absolute;
    right: 2%;
    top: 2%;
}