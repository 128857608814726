.btn-send-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #777777;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
}
.btn-send-message-position {
    position: absolute;
    right: 5%;
    top: 18%;
}
.container-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.info-libelle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(24, 24, 25, 0.9);
}
.info-value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
}
.btn-info-edit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #476DB6;

}
.navlink-fulbrighter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #001B4F;

}
.navlink-fulbrighter.active {
    border-bottom: 3px solid #003DA5 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #001B4F;

}
.navlink-fulbrighter:hover {
    border: none;
    color: #001B4F;
}
.navtabs-fulbrighter {
    border-bottom: none !important;
}
.title-post-fulbrighter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 40px;
    color: #42922E;

}
.author-post-fulbrighter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.7);

}
.date-post-fulbrighter {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(24, 24, 25, 0.9);
}

a.title-post-fulbrighter:hover {
    color: #42922E !important;
}