.titre-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    color: #383838;

}

.text-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #605C59;

}

.card-title-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #000000;
}

.date-publication-article {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #515151;
}

.card-text-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #434343;
}

.name-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 160%;
    color: #000000;
}

.contain-blog-text {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 160%;
    color: #434343;
    text-align: justify;
}

.text-blog-detail,
.text-blog-detail p,
.text-blog-detail ol,
.text-blog-detail ul,
.text-blog-detail pre,
.text-blog-detail blockquote,
.text-blog-detail h1,
.text-blog-detail h2,
.text-blog-detail h3,
.text-blog-detail h4,
.text-blog-detail h5,
.text-blog-detail h6 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    color: #434343;
    text-align: justify;
}


.list-article-blog {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    text-decoration-line: underline;
    color: #303030;
    padding: 8px 0;
}

.titre-blog-article {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 160%;
    color: #000000;
}

.blog-dans-cet-article {
    background: #F5F5F5;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 2rem;
}

.blog-article-titre {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 160%;
    color: #303030;
}

.pseudo-search {
    background: #FEFEFE;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 10px;
    width: 430px;
    height: 58px;
    justify-content: space-between;
}

.input-search-blog {
    border: none !important;
}

.text-categorie-blog {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #003DA5;
    font-family: 'DM Sans';
}

.no-link:hover {
    text-decoration: none;
}

img.article-home-img {
    height: 20vw;
    object-fit: cover;
}

.container-blog-article {
    padding-left: 6rem;
    padding-right: 6rem;
}

.span-icon-chat-blog {
    position: absolute;
    left: 2%;
    top: 25%;
}

.icon-post-container-comment-blog {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#signaler-comment .dropdown-toggle::after {
    display: none;
}