.banner-annuaire {
}
.titre-banner-annuaire {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 94.2%;
  letter-spacing: -0.025em;
  color: #42922e;
}
.bloc-banner2 {
  background: #d7e9f3;
  padding: 2rem 0;
}
.input-annuaire {
  border: none;
}
#basic-addon-annuaire {
  background: transparent;
  background: transparent;
  position: absolute;
  border: none;
  right: 1%;
  z-index: 9;
}
.bloc-filter-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select-annuaire {
  background: #fff;
  border-radius: 5px;
  border-radius: 5px !important;
  background: url(../../../assets/images/icons/Shape.png) 90% 47% / 13px
    no-repeat #fff !important;
  appearance: none;
  border: #fff !important;
}
.select-pays {
  height: calc(1.5em + .75rem + 2px);
}
.border-detail-annuaire {
  background: #eef4f7;
}
.coordonne-annuaire {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.name-annuaire {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  /* overflow-wrap: anywhere; */
}
.annuaire-pp {
  width: 94px;
  height: 94px;
  border-radius: 50%;
}
.fonction {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
#nav-annuaire-fulbrighter .nav-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 94.2%;
  text-align: center;
  letter-spacing: -0.025em;
  color: #000000;
  padding: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
#nav-annuaire-fulbrighter .nav-link.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 94.2%;
  text-align: center;
  letter-spacing: -0.025em;
  background: #ffffff !important;
  border-radius: 12px;
  color: #003da5 !important;
}
.img-pays-fulbrighter {
  width: 11px;
  height: 11px;
}
.bloc-annuaire-fulbrighter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bloc-annuaire-skills-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contain-item {
  background: #eceef2;
  border-radius: 3px;
  padding: 0.3rem 1rem;
}
.text-fulbrighter-annuaire {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.7);
  overflow-wrap: anywhere;
}
.PpFulbrighter-modal {
  width: 244px;
  height: 244px;
  border-radius: 5px;
}
.titre-fulbrighter-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 77px;
  letter-spacing: -2.50105px;
  color: #000000;
}
.sous-titre-fulbrighter-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.7);
}
.text-fulbrighter-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 175.35%;
  color: rgba(0, 0, 0, 0.7);
}
.btn-message-fulbrighter {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: #003da5;
  border-radius: 5px;
  color: #ffffff;
  width: 100%;
}
.btn-message-fulbrighter:hover {
  color: #003da5;
  border: 1px solid #003da5;
  background: transparent;
}
#VoirFulbrighter .modal-header {
  border-bottom: none;
}
.pays-origine {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
}
.border-bloc {
    background: #000;
    width: 1px;
    height: 130px;
}
.bloc-text-cataloguemodal {
    border-top: 1px solid #000;
}
.border-left-bloc-catalogue {
    border-left: 1px solid #000;
}

.annuaire-tag {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  background: #eceef2;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.7);
  /* padding: 8px; */
  margin: 0.2rem;
}
.container-bloc-card {
    height: 280px;
}
.position-btn-card-annuaire {
    position: absolute;
    bottom: 1%;
    left: 35%;
}

input.country-select__form-control.form-control {
  border: 1px solid #fff !important;
  background: url(../../../assets/images/icons/Shape.png) 90% 47% / 13px
    no-repeat #fff !important;
}