.header {
  background: #fff;
}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1080;
  left: 0;
  right: 0;
}

.sticky + .content {
  padding-top: 102px;
}
.component-front-header {
  background: #ffffff;

  height: 85px;
}
.link-top-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffffcc;
}
.link-top-header:hover {
  text-decoration: none;
  color: #ffffff;
}

.component-front-header .nav-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.291667px;
    color: #000000 !important;
    padding: 10px 15px;
    border-bottom: 2px solid transparent;
    white-space: nowrap;
}
#dropdown-finance ul {
    background: white;
      list-style: none;
      margin: 0;
      padding-left: 0;
  }
  
  #dropdown-finance  li {
      display: block;
      float: left;
      position: relative;
      text-decoration: none;
    transition-duration: 0.5s;
  }
    
  #dropdown-finance  li a {
    color: #000;
    text-decoration: none;
  }
  
  #dropdown-finance  li:hover {
      cursor: pointer;
  }
  
  #dropdown-finance  ul li ul {
    background: #fff;
    visibility: hidden;
    opacity: 0;
    min-width: 14rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 0rem;
    left: 0;
    display: none;
  }
  
  #dropdown-finance  ul li:hover > ul,
  ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    
  }
  ul li ul li .hover-finance:hover  {
    background-color: #BB4411;
    color: #fff !important;
    font-weight: bold;
  }
  #dropdown-finance  ul li ul li {
      clear: both;
    width: 100%;
  }
  .list-finance {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9) !important;
    border-bottom: 5px solid transparent;
  }
.component-front-header .nav-link:hover {
  border-bottom: 2px solid #003DA5;
}
.component-front-header .nav-link.active {
    border-bottom: 2px solid #003DA5;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.291667px;
    color: #003DA5 !important;

}

.dropdown-header {
  position: relative;
  display: inline-block;
}

.dropdown-content-header {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;
  top: 3.5rem;
}

.dropdown-header:hover .dropdown-content-header {
  display: block;
}
.dropdown-icon-top-header {
  width: 28px;
  height: 28px;
  color: #787878;
  background-color: #ffffff;
  border-radius: 50%;
}
.dropdown-icon-top-header svg {
  width: 18px;
}
.link-top-header.dropdown-toggle::after {
  position: absolute;
  top: 70%;
  right: -14px;
}

.link-header-login{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    background: #003DA5;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 10px;
}
.link-header-login:hover{
    text-decoration: none;
    color: #ffffff;
}
.header-scrolling-container{
    background-color: #fff;
    z-index: 999;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 5%);
    padding: 9px 0;
}

.connexion-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  top: 33px !important;
  min-width: 13rem;
  padding: 0.5rem 21px;
  margin: 0.125rem 0 0;
}
.connexion-dropdown .dropdown-toggle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #003DA5;
}
.connexion-dropdown .dropdown-toggle:hover,
.connexion-dropdown .dropdown-toggle:focus {
  text-decoration: none;
  color: #003DA5;
}
.connexion-dropdown .icon {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 1.5px solid #ffffff;
  background: #e3e3e3;
}
.icon-img img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1.5px solid #ffffff;
  /* background: #E3E3E3; */
}
.connexion-dropdown .dropdown-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 12px;
  padding: 1rem;
  color: #000000;
}

.connexion-dropdown .dropdown-item .icon {
  border: 1.5px solid #130f40;
}
.no-bg.active {
  background-color: inherit !important;
}
.no-bg:hover {
  background-color: #003DA5 !important;
  color: #fff;
}

#dropdown-finance ul {
  background: white;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#dropdown-finance li {
  display: block;
  float: left;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

#dropdown-finance li a {
  color: #000;
  text-decoration: none;
}

#dropdown-finance li:hover {
  cursor: pointer;
}

#dropdown-finance ul li ul {
  background: #fff;
  visibility: hidden;
  opacity: 0;
  min-width: 14rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 0rem;
  left: 0;
  display: none;
}

#dropdown-finance ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}
ul li ul li .hover-finance:hover {
  background-color: #003DA5;
  color: #fff !important;
  font-weight: bold;
}
#dropdown-finance ul li ul li {
  clear: both;
  width: 100%;
}

/* .hover-finance:hover {
        background-color: #003DA5;
        color: #fff !important;
        
  }
  .hover-finance a:hover {
    color: #fff !important;
    width: 100%;

  } */

.header-hiddenable-notification-ul {
  overflow-y: scroll;
  /* max-height: 300px; */
  max-height: 300px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar {
  width: 1px;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-track {
  background: transparent;
}

.header-hiddenable-notification-ul::-webkit-scrollbar-thumb {
  background-color: #003DA5;
  border-radius: 20px;
  border: 3px solid transparent;
}
.img-langue-choisie {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.icon-dropdown-menu svg {
    width: 15px;
}
.dropdown-item.active {
    background: transparent !important;
    color: #000 !important;
}