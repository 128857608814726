.titre-ajouter-catalogue-modal {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #B0B0B0;

}
.entreprise {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
}
.name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
}