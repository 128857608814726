/* .component-error404{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.component-error404 h1{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 155px;
    color: #003DA5;
    line-height: 1;
}
.component-error404 p{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
} */

#notfound {
    position: relative;
    height: 100vh;
}
#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    /* -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); */
    transform: translate(-50%, -50%);
}
.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}
.notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0px auto 20px;
    z-index: -1;
}
.notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 236px;
    font-weight: 200;
    margin: 0px;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    color: #211b19;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
}
.btn-return-accueil {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: #003DA5;
    font-size: 18px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.btn-return-accueil:hover {
    text-decoration: none;
    color: #003DA5;
    background-color: #fff;
    border: 1px solid #003DA5;
}