.auth-page-content {
    padding-bottom: 150px;
}
.auth-page-col {
    margin-top: 40px;
}
.auth-form-row {
    background: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 50px 25px;
}
.login-page-container {
    height: 100vh;
}
.auth-form-title {
    width: 100%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 29px;
    line-height: 39px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
}
.form-group.auth-form-group label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
}
.auth-form-control {
    background: #fff;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 30px 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
}
.auth-checkbox-container {
    display: flex;
    align-items: center;
}
.auth-checkbox-container label {
    margin-bottom: 0 !important;
    padding-left: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(24, 24, 25, 0.9);
}
input#session {
    width: 16px;
    height: 16px;
}
.auth-submit-btn {
    width: 100%;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    background: #003DA5;
    padding: 1rem;
}
.auth-submit-btn:hover {
    background: transparent !important;
    color: #003DA5 !important;
    border: 1px solid #003DA5;
}
.auth-reset-password-btn {
    background: transparent;
    width: 100%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.1px;
    color: #9FA2B4;

}
.auth-reset-password-btn:hover {
    color: #000 !important;
}
.icon-password {
    position: absolute;
    top: 53%;
    right: 3%;
}

.header-empty-login {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    padding: 20px 120px;
}
.connexion-page {
    overflow-x: hidden;
    height: 100vh;
}
.text-modifier-password {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #292929;
    opacity: 0.8;
}
.logo-ecompta {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.img-logo-ecompta {
    width: 48px;
    height: 48px;
}
.label-ecompta {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    /* text-transform: uppercase; */
    color: #9FA2B4;
}
.pas-de-compte {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #003DA5;

}
.sinscrire-btn-connexion {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #03065F;

}
.sinscrire-btn-connexion:hover {
    text-decoration: none;
    color: #03065F;
}
.pas-de-compte:hover {
    color: #003DA5;
    font-weight: bold;
    text-decoration: none;
}
.icon-idenfiant-mdp {
    position: absolute;
    top: 30%;
    left: 2%;
}
.logo-firsty-empty-header {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #272C96;

}
.img-logo-empty-header {
    width: 32px;
    height: 32px;
}
.text-mdp-modifier {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #272C96;
}
.btn-ok-modal {
    background: #272C96;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.color-green {
    color: #003DA5;

}
.copyright-connexion {
    font-family: 'Dm Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
}
.padding-hearder {
    padding: 40px 125px;
}