.name-auteur-forum {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 15px;
    color: #424242;
}
.icon-color {
    color: #003DA5;
}
.border-forum {
    background: #FFFFFF;
    border: 1px solid #003DA5;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 2rem;
}
.title-name-forum {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #5E5B5B;
}
.btn-chat {
    border: 1px solid #003DA5;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
    border-radius: 88px;
    padding: 1rem;
}
.bloc-information-forum {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img-profil {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}