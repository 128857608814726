.inputfile-box {
    position: relative;
  }
  
  .inputfile {
    display: none;
  }
  
  .container {
    /* display: inline-block; */
    width: 100%;
  }
  
  .file-box {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    height: calc(2rem - 2px);
  }
  
  .file-button {
    padding: 5px;
    position: absolute;
    top: 14%;
    right: 2%;
  }
  .input-add-document, .inputSelect__control {
    background: #F2F3F3 !important;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #1B1B1B;
    height: 49px;
    border: none !important;
    width: 100% !important;
  }
  .text-input-file-costum {
    position: absolute;
    white-space: nowrap;
    left: 2%;
    top: 30%;
  }