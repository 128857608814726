.Avatar-profil {
    width: 122px;
    height: 122px;
    border-radius: 8px;
}
.avatar-container {
    position: relative;
}
.icon-edit-avatar {
    position: absolute;
    right: 33%;
    top: 15%;
}
.identifiant-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
.profil-user-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}
.profil-user-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #1A1A1A;
}
.btn-change-password {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #FFFFFF;
    background: #003DA5;
    border-radius: 5px;
}
.btn-change-password:hover {
    color: #003DA5;
    border: 1px solid #003DA5;
    background: #fff;
}
.label-form-profil {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #1B1B1B;

}
.form-input-profil {
    background: #F2F3F3 !important;
    border-radius: 5px;
    height: 49px;
    border: none !important;
}
.icon-upload-form {
    position: absolute;
    right: 5%;
    top: 46%;
}
.btn-theme-modal {
    background: #003DA5;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #FFFFFF;

}
.btn-cancel-form {
    background: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
}
.titre-profil {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 47px;
    line-height: 94.2%;
    text-align: center;
    letter-spacing: -0.025em;
    color: #000000;
}
.div-change-password-profil {
    margin-bottom: 6%;
}