
.component-navbar-mobile-v2{
    
}
.navbar-toggler-icon {
    background-image: url(../../../images/icons/menu.png) !important;
}
#scrollbar-nav-mobile {
    height: 100%;
    overflow-y: auto;
}
#scrollbar-nav-mobile::-webkit-scrollbar {
    display: none;
  }
  .content-nav-mobile {
    height: 350px;
}