
.component-front-footer {
    background: #003DA5;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.head-footer{
    border-bottom: 1px solid #5A5A5A;
}
.body-footer{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.end-footer{
    border-top: 1px solid #5A5A5A;
    display: flex;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

footer .logo{
    width: 160px;
}
.link-top-footer {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.231818px;
    color: #FFFFFF;
    background: #42922E;
    border-radius: 8px;
    padding: .7rem 2rem .7rem 2rem;
}
.text-footer-span {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.3px;
    color: #FFFFFF;
}
.text-footer:hover {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
.link-top-footer:hover {
    color: #42922E;
    border: #42922E;
    background-color: #fff;
    text-decoration: none;
}
.footer-grid .title-footer{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.231818px;
    color: #42922E;
}
.input-email-footer {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.204545px;
    /* color: #FFFFFF; */
    background: #fff;
    border: none;
    border-bottom: 1px solid #5A5A5A !important;
    padding: 1rem;
    opacity: 0.1;
    width: 100%;
}
.btn-submit-footer {
    background: #42922E;
    border-radius: 8px 8px 0px 0px;
    padding: 14px;
    position: absolute;
    right: 0;
}
.icon-submit-footer {
    color: #fff;
}
.text-footer {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.204545px;
    color: #FFFFFF;
}
.icon-social-footer {
    width: 9px;
    height: 17px;
}
.footer-grid .text-content{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #FFFFFF !important;
}
.footer-grid .link-footer{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #FFFFFF !important;
}
.footer-grid .link-footer:hover{
    text-decoration: none;
    color: #F9CA24;
}
.footer-grid .contact-text{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #FFFFFF !important;
}
.footer-grid .contact-text:hover{
    color: #FFFFFF !important;
    text-decoration: none;
}
.footer-grid .contact-text>svg{
    width: 14px;
    position: absolute;
    left: 15px;
}
.social-networking p{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}
.social-networking a {
    padding-left: .5rem;
    padding-right: .5rem;
}
.social-networking span {
    padding-left: 2rem;
    padding-right: 2rem;
}
.social-networking a:hover{
    transform: scale(1.2);
    opacity: 1;
}
.social-networking img{
    width: 100%;
}

footer .copyright{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.75);
    display: inline-block;
}
.link-end-footer{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.75);
}
.link-end-footer:hover{
    text-decoration: none;
    color: #F9CA24;
}
.btn-n-c-f-c {
    background: transparent;
}

#formulaireDeContact {
    z-index: 9999999;
}
.div-footer-warc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-tabs-page-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 25px;
    color: #282829;
}
.nav-tabs-page-content.active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #003DA5 !important;
    border-bottom: 2px solid #003DA5;
    background: transparent !important;
}
.sous-titre-page-content-front {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #282829;
}
.text-version-page-content-front {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    color: #282829;

}
.text-color-page-content-front {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    color: #003DA5;
}
.text-page-content-front {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #282829;
    text-align: justify;
}
.content-start-page-cgu {
    padding-top: 8%;
}