.border-detail-dossier {
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: space-around;
}

.details-dossier-item .card-body {
  padding: 0 0.6rem !important;
}
.details-dossier-item.media .card-body {
  padding: 0px !important;
}
.details-dossier-item.media .card {
  margin-bottom: 5px !important;
}
.title-liste-dossier-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.bloc-card {
  background: #ffffff;
  box-shadow: 4px 10px 35px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 100%;
}
.banner {
  width: 100%;
  background: url(../../../assets/images/banner.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.titre-banner {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 47px;
  line-height: 94.2%;
  letter-spacing: -0.025em;
  color: #000000;
}
#basic-addon2 {
  border: none !important;
  background: #003da5;
  border-radius: 10px;
  position: absolute;
  right: 1%;
  width: 148.35px;
  height: 38.78px;
  top: 9%;
  padding-left: 2rem;
  z-index: 99;
}
#basic-addon2:hover {
  color: #fff;
  background: #003da5;
}
.padding-input {
  padding: 1.5rem 1rem;
  border: none;
  border-radius: 10px;
}
.btn-search-doc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}
.banner .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.hWhVGe .s-header {
  height: 3rem !important;
  width: 100% !important;
  margin-bottom: 0.25rem !important;
}
.accordion {
  border: none !important;
}
.accordion .accordion-item .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #003da5 !important;
  background: #fff !important;
}
.guide-accordion .accordion-item.active .panel {
  max-height: 100% !important;
  overflow: auto !important;
}
.accordion .accordion-item .panel {
  background: #fff !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #003da5;
}

.accordion .accordion-item .panel p div, 
.accordion .accordion-item .panel p div p,
.accordion .accordion-item .panel p div ol,
.accordion .accordion-item .panel p div ul,
.accordion .accordion-item .panel p div pre,
.accordion .accordion-item .panel p div blockquote,
.accordion .accordion-item .panel p div h1,
.accordion .accordion-item .panel p div h2,
.accordion .accordion-item .panel p div h3,
.accordion .accordion-item .panel p div h4,
.accordion .accordion-item .panel p div h5,
.accordion .accordion-item .panel p div h6 {
  font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    color: #434343;
    text-align: justify;
}
.total-nombre-facultes {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.montant-catalogue {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.bloc-catalogue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.border-milieu {
  background: #dee2e6;
  width: 1px;
  height: 120px;
}
.btn-voir-catalogue {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #003da5;
  background: transparent;
}
.title-catalogue-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #003da5;
}
.sub-title-catalogue-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}
.bloc-div-border {
  border: 1px solid #000;
  display: flex;
  align-items: center;
}
.list-type-li {
  list-style: none;
}
.sous-title-catalogue-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #003da5;
}
.body-content-catalogue-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000;
}
.link-contact-catalogue {
  text-decoration: underline;
  color: #6095f1;
}
.sc-ksdxgE {
  display: none;
}

.sub-title-catalogue-modal p strong {
  color: #000;
}

.details-dossier-item:hover .play-overlay {
  display: flex !important;
}
.content-media-read {
  background: #d7e9f3;
}
.content-text-media-read {
  padding: 4%;
}
.title-description-media-read {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
  color: #5e5e5e;
}
.text-description-media-read {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: justify;
  color: #5e5e5e;
}
.title-others-videos-media {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
