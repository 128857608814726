.texte-page-cgu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 141.5%;
  color: #000000;
  text-align: justify;
}
.btn-edit-page-setting {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* color: #42922E; */
  background: transparent;
  border: none;
}
.btn-add-content-position {
  position: absolute;
  right: 5%;
  top: 5%;
}

.entete-modal-agenda {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 43px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}
.bloc-date-modal-agenda {
  background: #ffffff;
  box-shadow: 0px 2px 43px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.label-form-agenda-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.input-form-agenda-modal {
  background: transparent;
  border: none;
}
.input-form-agenda-modal-white {
  background: #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #cccccc;
  height: 40px;
}
.time-icon-modal {
  width: 14.17px;
  height: 16.67px;
}
.icon-form-modal {
  position: absolute;
  left: 6%;
  top: 19%;
}
.date-courante-agenda {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
}
.chiffre-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: rgba(0, 0, 0, 0.7);
}
.input-event-agenda {
  border-bottom: 2px solid #d6d6d6;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #cccccc;
}
.label-form-add-event {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 6px;
}
.input-add-event {
  background: #ffffff;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 16px;
  color: #71717a;
  border: none;
}
.input-group-text {
  background: #fff;
  border: none;
}
.input-group {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}
.textarrea-event {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 18px;
}

.container-banner-admin {
  position: relative;
  /* background-color: #B5853B; */
  border-radius: 12px;
  /* padding: 100px 30px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 400px; */
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  /* height: auto; */
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.titre-banner-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 135%;
  letter-spacing: 0.291667px;
  color: #ffffff;
}
.bloc-titre-admin {
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;
  padding-bottom: 4%;
  margin-left: 10%;
  margin-right: 10%;
  white-space: normal;
}
.container-banner-admin:hover {
  background-color: grey;
  border-radius: 12px;
}
.container-banner-admin:hover .image {
  opacity: 0.7;
}

.container-banner-admin:hover .middle {
  opacity: 1;
}

.banner-admin-dashboard {
  width: 100%;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 400px;
  border-radius: 10px;
}

.ajouter-btn-modal {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  width: 200px;
  border: 1px solid transparent;
}
.ajouter-btn-modal:hover,
.ajouter-btn-modal:focus {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
