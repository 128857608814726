.component-dashboard-coordonnateur {
}
.content-dashboard-start {
  padding-top: 80px;
}

.component-dashboard-coordonnateur .statistics .icon-statistics {
  color: #f9ca24;
  width: 60px;
  height: 60px;
}
.component-dashboard-coordonnateur .statistics .icon-statistics path {
  stroke-width: 0.5px;
}
.component-dashboard-coordonnateur .statistics .title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #383639;
}
.component-dashboard-coordonnateur .statistics .chiffre {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: right;
  color: #383639;
}
.component-dashboard-coordonnateur .statistics .link-statistics {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #383639;
  display: flex;
  align-items: center;
}
.component-dashboard-coordonnateur .statistics .link-statistics:hover {
  text-decoration: none;
  color: #f9ca24;
}
.component-dashboard-coordonnateur .statistics .link-statistics svg {
  color: #c4c1c1;
  margin-right: 10px;
  width: 17px;
  height: 17px;
}
.front-card {
  background: #ffffff;
}
.dashboard-card .title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.dashboard-card .sub-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #11263c;
}

.dashboard-card .chart-date {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 7%);
  border-radius: 100px;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #252733;
  height: 45px;
  padding-left: 2rem;
  padding-right: 2rem;
  appearance: none;
  background: url(../../../../assets/images/icons/Shape.png) 96% / 15px
    no-repeat;
}
.navbar-vertical-coordonnateur {
  top: 145px !important;
}

.apexcharts-toolbar {
  z-index: auto !important;
}
