.btn-download-document {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 94.2%;
    letter-spacing: -0.025em;
    color: #000000;
    text-align: center;
    padding: .5rem 1.5rem;
}
.bloc-entete-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-view-start {
    overflow: auto;
}