/* .component-front-home{
    background-color: #fff !important;
} */
.bloc-div-chiffre {
    background: #FFFFFF;
    border: 1px solid #BB4411;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.icon-chiffre {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 62px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: #DDDDDD;
}

.texte-chiffre {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.6);
}

.custom-control-input:checked~.input-item-step-checkout::before {
    color: #fff;
    border-color: #B5853B;
    background-color: #B5853B;
}

.custom-radio .input-item-step-checkout::before {
    border-radius: 50%;
    border: 2px solid #B5853B;
}

.custom-control-label-form-signup {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #5A5A5A;
}

.custom-checkbox .custom-control-label-form-signup::before {
    border-radius: 0rem;
    border: 2px solid #B5853B;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label-form-signup::after {
    background-color: #B5853B;
}

.custom-control-input:checked~.checkbox-steps-inscription-vendeur::before {
    color: #fff;
    border-color: #B5853B;
    background-color: #B5853B;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.custom-control-label-filter {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.7);
}


.custom-checkbox .custom-control-label-filter::before {
    border-radius: 0rem;
    border: 1px solid #000000;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label-filter::after {
    background-color: #B5853B;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label-filter::after {
    background-color: #B5853B;
}

.custom-control-input:checked~.checkbox-steps-inscription-vendeur::before {
    color: #fff;
    border-color: #B5853B;
    background-color: #B5853B;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photo-pp {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.nom-pp {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #000000;
}

.bg-accueil {
    background: #fff;
    border: 1px solid #DEE0E1;
    border-radius: 2px;
    padding: 1rem;
}

.btn-modal-add-question {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #6F6F6F;
    background: #EBEEEA;
    border-radius: 5px;
}

.photo-pp-post {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.heure-post {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #979797;

}

.text-post-bold {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    font-family: 'DM Sans';
    color: #282829;
}

.text-post-normal,
.text-post-normal p,
.text-post-normal ol,
.text-post-normal ul,
.text-post-normal pre,
.text-post-normal blockquote,
.text-post-normal h1,
.text-post-normal h2,
.text-post-normal h3,
.text-post-normal h4,
.text-post-normal h5,
.text-post-normal h6 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #282829;
}

.show-hide-text {
    display: flex;
    flex-wrap: wrap;
}

.show-hide-text a {
    order: 2;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #003DA5;
    text-decoration: none;
    cursor: pointer;
}

.show-hide-text p {
    position: relative;
    overflow: hidden;
    /* max-height: 60px; */
    line-height: 20px;
}

.show-hide-text p:after {
    /* content: ""; */
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

/* @supports (-webkit-line-clamp: $rows) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    .show-hide-text p:after {
        display: none;
   }
} */
.show-less {
    display: none;
}

.show-less:target {
    display: block;
}

.show-less:target~p {
    display: block;
    max-height: 100%;
}

.show-less:target+a {
    display: none;
}

.wrapper {
    margin: 20px auto;
}

.icon-post-container {
    display: flex;
    align-items: center;
}

.icon-comment-post {
    color: #A5A8AD;
}

.icon-comment-post-like {
    color: #19C587;
}

.icon-comment-post-dislike {
    color: #FF3D00;
}

.text-comment-post {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #636466;
}

.text-consultation-agenda {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.text-agenda-calendar {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9B9B9B;
}

.text-agenda {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #282829;
}

.titre-filter-type {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #282829;
}

.form-checkbox-input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.checkbox-input label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
}

.checkbox-input label input {
    position: absolute;
    left: -9999px;
}

.checkbox-input label {
    margin-top: 6px;
    margin-bottom: 6px;
}

.checkbox-input label input:checked+span {
    /* border: 1px solid #003DA5; */
    box-sizing: border-box;
    padding: 15px;
    font-weight: bold;
}

.checkbox-input label input:checked+span:before {
    box-shadow: inset 0 0 0 0.4375em #003DA5;
}

.checkbox-input label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 10px;
    transition: 0.25s ease;
    padding: 5px 15px;
    width: 100%;
}

/* .checkbox-input label span:hover {
    border: 2px solid #003DA5;
} */
.checkbox-input label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: none !important;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #666666;
}

.container-checkbox-input {
    width: 100%;
    /* display: flex; */
    align-items: center;
}

/* .btn-close-modal-comment {
    background: #E4E6EB !important;
    border-radius: 50%;
    width: 33px;
    height: 33px;
} */
.btn:focus {
    box-shadow: none !important;
}

.text-titre {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
}

.input-comment-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #BDC0C4;
    font-family: 'DM Sans';
    /* border: none; */
    background: transparent;
}

.input-file-modal {
    border: 1px solid #E4E6EB;
    box-sizing: border-box;
    border-radius: 5px;
    /* padding: 1rem; */
}

.span-icon-file {
    position: absolute;
    left: 3%;
    top: 21%;
}

.modal-footer-no-border {
    border-top: none !important;
}

.btn-publier-comment {
    background: #003DA5;
    border-radius: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    width: 100%;
    padding: 1rem;
}

.btn-publier-comment:hover {
    color: #003DA5;
    border: 1px solid #003DA5;
    background: #fff;
}

.btn-publier-comment:disabled {
    background: #EBEEEA;
    border-radius: 5px;
    font-family: 'DM Sans';
    border: 1px solid #003DA5;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #BDC0C4;

}

/* .btn-like:focus .icon-comment-post{
    color: #19C587 !important;
}
.btn-dislike:focus .icon-comment-post{
    color: #FF3D00 !important;
} */
.span-icon-chat {
    position: absolute;
    left: 4%;
    top: 31%;
}

.input-add-comment {
    border: none !important;
}

.comment-content {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #282829;

}

.comment-from-name {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #2B2B2B;
}

.container-comment-from {
    background: #ECEFED;
    border-radius: 0 20px 20px;
}

.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);

.fa-2x {
    font-size: 2em;
}

.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}


.main-menu:hover,
nav.main-menu.expanded {
    width: 250px;
    overflow: visible;
}

.main-menu {
    background: #212121;
    border-right: 1px solid #e5e5e5;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 60px;
    overflow: hidden;
    -webkit-transition: width .05s linear;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 1000;
}

.main-menu>ul {
    margin: 7px 0;
}

.main-menu li {
    position: relative;
    display: block;
    width: 250px;
}

.main-menu li>a {
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    color: #999;
    font-family: arial;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    -webkit-transition: all .1s linear;
    transition: all .1s linear;

}

.main-menu .nav-icon {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}

.main-menu .nav-text {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 190px;
    font-family: 'Titillium Web', sans-serif;
}

.main-menu>ul.logout {
    position: absolute;
    left: 0;
    bottom: 0;
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

a:hover,
a:focus {
    text-decoration: none;
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
    color: #fff;
    background-color: #5fa2db;
}

.area {
    float: left;
    background: #e2e2e2;
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff) format('woff');
}

.menu-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #282829;

}

#sidebar {
    background: #fff;
    width: 330px;
    padding: 2rem 1rem;
    display: block;
    position: absolute;
    left: -336px;
    top: 15px;
    transition: left 0.3s linear;
    z-index: 99;
}

#sidebar.visible {
    left: 0px;
    transition: left 0.3s linear;
}

#sidebar ul {
    margin: 0px;
    padding: 0px;
}

#sidebar ul li {
    list-style: none;
}

#sidebar ul li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #282829;
}

#sidebar-btn {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 15px;
    cursor: pointer;
    margin: 20px;
    position: absolute;
    top: 0px;
    right: -60px;
}

#sidebar-btn span {
    height: 1px;
    background: #111;
    margin-bottom: 5px;
    display: block;
}

#sidebar-btn span:nth-child(2) {
    width: 75%;
}

#sidebar-btn span:nth-child(3) {
    width: 50%;
}

.view-start-mobile {

    margin-top: 25%;
}

.img-event-participate {
    width: auto;
    height: auto;
}

.btn-participate-event {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    background: #FFFFFF;
    border: 1px solid #003DA5;
    box-sizing: border-box;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.btn-participate-event.active {
    color: #FFFFFF;
    background: #003DA5;
    border: 1px solid #FFFFFF;
}

.btn-toggle-sous-menu {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #282829;
}

.div-content-sous-menu {
    border: none !important;
}

.div-content-sous-menu a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding-top: 15px;
    padding-bottom: 15px;
}

.nav-link-vertival-custom:hover {
    width: 100%;
    text-align: left;
}

/* menu mobile */
.bbTnlm {
    top: -1% !important;
}

.cFmpsW {
    position: inherit !important;
}

div#cke_editor1 {
    display: none !important;
}

.ql-editor {
    min-height: 130px !important;
}