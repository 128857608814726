.section-banner {
  /* background-image: url(../../images/warc-banniere.png); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 700px;
  background-position: 50%;
  position: relative;
}
.titre-banner-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 66px;
  line-height: 135%;
  letter-spacing: 0.291667px;
  color: #ffffff;
}
.bloc-contain-section-banner {
  padding-top: 8%;
  padding-bottom: 6%;
  padding-left: 8%;
}
.bloc-titre-landingpage {
  padding-bottom: 4%;
}
.btn-login-landingpage {
  background: #003da5;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}
.btn-login-landingpage:hover {
  color: #003da5;
  border: 1px solid #003da5;
  background: #fff;
  text-decoration: none;
  font-weight: bold;
}
.btn-register-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.65);
}
.btn-register-landingpage:hover {
  text-decoration: none;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  font-weight: bold;
}
.bloc-div-a-propos {
  background: #ffffff;
  border: 1px solid #003da5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 273px;
}
.text-chiffres-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 87px;
  line-height: 138%;
  letter-spacing: 0.291667px;

  color: #000000;
}
.text-bloc-paragraphe-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 166%;
  letter-spacing: 0.291667px;

  color: #767676;
}
.bloc-div-a-propos:hover {
  background: #ffffff;
  border: 1px solid #003da5;
  box-sizing: border-box;
  box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
.bloc-titre-landingpage {
  width: 70%;
}
/* .bloc-div-a-propos .bloc-content-div-after {
    display: none;
}
.bloc-div-a-propos:hover .bloc-content-div {
    display: none;
}
.bloc-div-a-propos:hover .bloc-content-div-after {
    display: inline;
    transition: all 0.2s;
   
} */
.section-a-propos-landingpage {
  background: #fff;
  padding-top: 7%;
  padding-bottom: 8%;
}
.titre-a-propos {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 135%;
  letter-spacing: 0.291667px;
  text-transform: uppercase;
  color: #003da5;
}
.text-a-propos-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 166%;
  letter-spacing: 0.291667px;
  color: #000000;
  text-align: justify;
}
.position1 {
  position: absolute;
  left: 10%;
}
.position2 {
  position: absolute;
  right: 0;
  top: 7%;
}
.position3 {
  position: absolute;
  top: 57%;
  left: 10%;
}
.position4 {
  position: absolute;
  top: 64%;
  right: 0;
}
.section-avantages-landingpage {
  background: #f4f8fa;
  padding-top: 7%;
  padding-bottom: 8%;
}
.titre-avantage-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.291667px;
  color: #003da5;
}
.bloc-div-avantage {
  background: #fff;
  padding: 3rem;
}
.img-avanatage {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.sous-titre-avantage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 135%;
  letter-spacing: 0.291667px;
  color: #000000;
}
.text-avantage-landingpage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 166%;
  letter-spacing: 0.291667px;
  color: #000000;
  text-align: justify;
}
.section-fonctionnalite {
  background: linear-gradient(113.38deg, #003da5 10.56%, #00286a 78.44%);
  padding-top: 7%;
  padding-bottom: 8%;
}
.titre-fonctionnalite {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.291667px;
  color: #ffffff;
}
.list-fonctionnalite {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 166%;
  letter-spacing: 0.291667px;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px;
}
.component-footer-landingpage {
  background: #001b4a;
}
.padding-header-landingpage {
  padding: 0 125px;
  background: #fff !important;
}
.btn-float-signaler {
  position: fixed;
  width: 80px;
  height: 65px;
  bottom: 90px;
  right: 0;
  border-radius: 50px;
  text-align: center;
  z-index: 99;
}
.btn-float-contacter {
  position: fixed;
  width: 80px;
  height: 65px;
  bottom: 20px;
  right: 0;
  border-radius: 50px;
  text-align: center;
  z-index: 99;
}
.marge-bloc-btn-sm {
  position: absolute;
  width: 100%;
  bottom: 15%;
}

.react-slideshow-container {
  position: relative;
}

.react-slideshow-container + ul.indicators {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 5%;
  color: #fff;
  z-index: 1000;
}
.react-slideshow-container + ul.indicators li {
  margin-left: 3px;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator {
  border: 0;
  opacity: 0.25;
  cursor: pointer;
  background: transparent;
  color: transparent;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background: #fff;
  text-align: center;
}
.react-slideshow-container .default-nav {
  height: 45px !important;
  background: #003da5;
  width: 45px !important;
}
.default-nav svg {
  color: #fff !important;
}
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sm-container {
  text-align: center;
}

.sm-container i {
  color: #f5f6fa;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.4s;
}

.sm-container i:hover {
  transform: scale(1.2);
}

.sm-menu {
  background: #487eb0;
  position: absolute;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.sm-menu.active {
  visibility: visible;
  opacity: 1;
}

.sm-menu::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #487eb0;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
