#appointment{
    background: rgba(14, 165, 233, 0.1) !important;
}
.VerticalAppointment-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0369A1 !important;
}